import * as _locale3 from "element-ui/lib/locale";
var _locale2 = "default" in _locale3 ? _locale3.default : _locale3;
var exports = {};
exports.__esModule = true;
var _locale = _locale2;
exports.default = {
  methods: {
    t: function t() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return _locale.t.apply(this, args);
    }
  }
};
export default exports;
export const __esModule = exports.__esModule;